/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/zepto@1.2.0/dist/zepto.min.js
 * - /npm/lottie-web@5.5.0/build/player/lottie.min.js
 * - /npm/gsap@3.6.0/dist/gsap.min.js
 * - /npm/pixi.js@5.1.5/dist/pixi.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
